
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import membersApi from "@/core/services/MembersApi";
import Filters from "./crafted/widgets/Filters.vue";
import FilterTags from "./crafted/widgets/FilterTags.vue";
import { hideModal } from "@/core/helpers/dom";
import { object, string } from "yup";
import { Field, Form } from "vee-validate";

export default defineComponent({
    name: "Archives",
    components: { Filters, FilterTags, Field, Form },
    data() {
        return {
            tagsKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: false,
                bailliage: true,
                commander: false,
                country: false,
                dues: false,
                function: false,
                gender: false,
                grade: true,
                medal: false,
                member_type: false,
                other_association: false,
                period: false,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotions: false,
                price_category_dues: false,
                type_dues: false,
                relevant_year_dues: false,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_actual_balance: false,
                biller_initial_balance: false,
            },
            relevantYear: "",
            loading: true,
            archiveSearch: "",
            periodFrom: "",
            periodTo: "",
            selectedValue: "",
            filterGradeSearch: "",
            archives: [] as any,
            currentPage: this.savedPage ? this.savedPage : (1 as any),
            archivesCount: 0,
            totalPages: 0,
            selectedArchives: [] as any,
            actualFilter: "",
            actualCol: "",
            archivesTh: [
                { label: "ID", class: "min-w-60px", colName: "id" },
                { label: "Name", class: "min-w-60px", colName: "lastname" },
                {
                    label: "Firstname",
                    class: "min-w-60px",
                    colName: "firstname",
                },
                { label: "Grade", class: "max-w-60px", colName: "grade" },
                {
                    label: "Nat. Bailliage",
                    class: "min-w-150px",
                    colName: "national_bailliage",
                },
                {
                    label: "PRO/REG Bailliage",
                    class: "min-w-175px",
                    colName: "provincial_bailliage",
                },
                { label: "Status", class: "min-w-60px", colName: "status" },
                {
                    label: "Termination Date",
                    class: "min-w-125px",
                    colName: "archive_date",
                },
            ],
            perm_pos: "",
            reinstateAmount: "",
            priceCategories: [] as any,
            reinstateTypes: [] as any,
            perimeters: [] as any,
            reinstate: {
                type: "",
                perimeter: "",
                price_category: "",
                relevant_year: "" as any,
                date: "",
            },
            renewal: {
                type: "",
                perimeter: "",
                price_category: "",
                relevant_year: "" as any,
                date: "",
                penalty: false,
            },
            price_cat_options: [] as any,
            price_cat_options_next_step: [] as any,
            disablePriceCategory: false,
            focusedMemberId: "",
            lockDueYear: false,
            lockRenewalYear: false,
            disableDuePerimeter: false,
            disableRenewalPerimeter: false,
            disableDuePriceCategory: false,
            disableRenewalPriceCategory: false,
            disableRenewalType: true,
            showRenewal: false,
            dueLabel: "Reinstatement",
            memberIsOMGD: "",
            memberIsYoung: "",
            memberSpouseUpToDate: "",
            renewalAmount: "",
            blockDue: false,
            omgdModal: false,
            addOMGDAdm: false,
            memberId: "",
            archiveId: "",
            memberFirstname: "",
            memberLastname: "",
            memberCode: "",
            targetBailliage: "",
            nBailliages: [] as any,
            archiveBailliage: {} as any,
            archiveNewMail: "",
            allChecked: false,
            selectionScope: "add",
            selectionPayload: {} as any,
            exportProgressMsg: "",
            exportDialog: false,
            showSpinner: true,
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const router = useRouter();
        const route = useRoute();
        const reinstateModalRef = ref<null | HTMLElement>(null) as any;
        const createTransferRef = ref<null | HTMLElement>(null) as any;
        const updateMailRef = ref<null | HTMLElement>(null) as any;
        var savedPage: any;

        const reinstateSchema = object().shape({
            renewalType: string().required().label("Type").nullable(),
            renewalPerimeter: string().required().label("Perimeter").nullable(),
            renewalPriceCategory: string()
                .required()
                .label("Price category")
                .nullable(),
            renewalYear: string().required().label("Renewal year").nullable(),
            renewalDate: string().required().label("Renewal date").nullable(),
        });

        const transferSchema = object().shape({
            targetBailliage: string()
                .required()
                .label("Target Bailliage")
                .nullable(),
        });

        const updateMailSchema = object().shape({
            archiveNewMail: string()
                .email()
                .required()
                .label("Email")
                .nullable(),
        });

        const closeReinstateModal = () => {
            hideModal(reinstateModalRef.value);
        };

        const closeTransferModal = () => {
            hideModal(createTransferRef.value);
        };

        const closeUpdateMailModal = () => {
            hideModal(updateMailRef.value);
        };

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }
        if (route.params.page) {
            var temp = route.params.page;
            if (temp) savedPage = +temp;
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Archives", []);
        });

        return {
            resultsPerPage,
            router,
            savedPage,
            debounce: createDebounce(),
            reinstateSchema,
            reinstateModalRef,
            closeReinstateModal,
            transferSchema,
            createTransferRef,
            closeTransferModal,
            updateMailSchema,
            updateMailRef,
            closeUpdateMailModal,
        };
    },
    mounted() {
        this.loading = false;
        this.getFields();
        this.getArchives();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
        var reinstateModal = document.getElementById("reinstateModal");
        reinstateModal?.addEventListener(
            "hidden.bs.modal",
            function (this: any) {
                this.clearReinstateForm();
            }.bind(this)
        );
    },
    methods: {
        handleSelection(scope: string, event: any, member?: any) {
            if (scope === "top") {
                event.target.checked
                    ? (this.allChecked = true)
                    : (this.allChecked = false);
            } else if (scope === "single") {
                this.allChecked
                    ? (this.selectionScope = "remove")
                    : (this.selectionScope = "add");
                if (event.target.checked) {
                    this.allChecked
                        ? this.selectedArchives.splice(
                              this.selectedArchives.indexOf(member.id),
                              1
                          )
                        : this.selectedArchives.push(member.id);
                } else {
                    this.allChecked
                        ? this.selectedArchives.push(member.id)
                        : this.selectedArchives.splice(
                              this.selectedArchives.indexOf(member.id),
                              1
                          );
                }
            }

            this.selectionPayload = {
                type: this.selectionScope,
                ids: this.selectedArchives,
            };
        },
        exportMembers() {
            this.exportDialog = this.showSpinner = true;
            this.exportProgressMsg =
                "Retrieving data from the selected members...";
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        status: "archived",
                        filters: this.activeFilters,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        status: "archived",
                        filters: this.activeFilters,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    status: "archived",
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    status: "archived",
                };
            }

            if (this.archiveSearch) {
                payload = { ...payload, query: this.archiveSearch };
            }

            if (Object.keys(this.selectionPayload).length !== 0) {
                payload = { ...payload, selection: this.selectionPayload };
            }

            membersApi.exportMembers(payload).then((res: any) => {
                this.exportDialog = this.showSpinner = false;
                if (res.success) {
                    window.open(res.data.member_export, "_blank")?.focus();
                }
            });
            console.log(payload);
        },
        checkArchive(id: number) {
            membersApi
                .archiveMembers({ members: [id], status: "active" })
                .then((res: any) => {
                    if (res.success) {
                        this.fillReinstateForm(id);
                        document.getElementById("hidden-trigger")?.click();
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                res.error && res.error.errorMessage
                                    ? res.error.errorMessage
                                    : "This member cannot be reinstated.",
                            duration: 10000,
                            showClose: true,
                        });
                        if (res.error.type && res.error.type === "email") {
                            document
                                .getElementById("hidden-trigger-mail")
                                ?.click();
                        }
                    }
                });
        },
        updateArchiveMail() {
            membersApi
                .updateMember(this.archiveId, {
                    contact: {
                        email: this.archiveNewMail,
                    },
                })
                .then((res: any) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message: "Email updated successfully",
                        });
                        this.archiveNewMail = "";
                        this.closeUpdateMailModal();
                        this.fillReinstateForm(this.archiveId);
                        document.getElementById("hidden-trigger")?.click();
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                res.error && res.error.errorMessage
                                    ? res.error.errorMessage
                                    : "An error occurred while updating the email",
                        });
                    }
                });
        },
        filterBailliages() {
            this.nBailliages = [];
            membersApi.getBailliages().then((res) => {
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (
                        bailliage.type === "National" &&
                        bailliage.id !== this.archiveBailliage.id
                    )
                        this.nBailliages.push(bailliage);
                });
            });
        },
        transferMember() {
            membersApi
                .transferMember(this.archiveId, {
                    national_bailliage: this.targetBailliage,
                })
                .then((res: any) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message:
                                this.perm_pos === "0"
                                    ? "Member transferred successfully"
                                    : "Transfer request sent to HQ.",
                        });
                        this.closeTransferModal();
                        this.getArchives();
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                "An error occurred while transferring the member",
                        });
                    }
                });
        },
        getFields() {
            membersApi
                .getDuesTypes({ p: 1, presult: 9999, reinstate: "" })
                .then((res) => {
                    this.reinstateTypes = res.data.object_list;
                    this.reinstateTypes = this.reinstateTypes.filter(
                        (item: any) =>
                            item.code === "REIN" || item.code === "RENEW"
                    );
                    this.reinstate.type = this.reinstateTypes[0].id;
                });
            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.price_cat_options = this.priceCategories =
                        res.data.object_list;
                    this.priceCategories.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                    this.price_cat_options.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                });
        },
        clearReinstateForm() {
            this.reinstate = {
                type: "",
                perimeter: "",
                price_category: "",
                relevant_year: "" as any,
                date: "",
            };
            this.renewal = {
                type: "",
                perimeter: "",
                price_category: "",
                relevant_year: "" as any,
                date: "",
                penalty: false,
            };
            this.showRenewal = false;
            this.dueLabel = "Reinstatement";
            this.memberId = "";
            this.memberIsOMGD = "";
            this.memberIsYoung = "";
            this.memberSpouseUpToDate = "";
            this.getFields();
        },
        initRenewal() {
            ElMessage({
                message: "You should now add a Renewal due.",
                type: "info",
            });
            this.showRenewal = true;
            this.dueLabel = "Renewal";
            this.reinstateTypes.map((item: any) => {
                if (item.code === "RENEW") this.renewal.type = item.id;
            });

            membersApi
                .getSpecificMember(this.focusedMemberId)
                .then((res: any) => {
                    this.memberFirstname = res.data.userprofile.firstname;
                    this.memberLastname = res.data.userprofile.lastname;
                    this.memberCode = res.data.code;
                    if (res.data.is_omgd) {
                        this.perimeters.push({ id: 2, label: "OMGD" });
                    } else {
                        this.renewal.perimeter = "CDR";
                        this.disableRenewalPerimeter = true;
                    }
                    this.fetchPriceCategories("renewal");

                    const currentMonthNumber = new Date().getMonth() + 1;

                    if (currentMonthNumber >= 1 && currentMonthNumber <= 8) {
                        this.renewal.relevant_year = new Date()
                            .getFullYear()
                            .toString();
                    } else {
                        this.renewal.relevant_year = (
                            new Date().getFullYear() + 1
                        ).toString();
                    }
                    this.setDate();
                });
        },
        setDate() {
            // const result = new Date('2022', '2', '28').toLocaleDateString('en-GB');

            var date = new Date().getDate() as any;
            var month = (new Date().getMonth() + 1) as any;
            var year = new Date().getFullYear();

            if (date < 10) date = "0" + date;
            if (month < 10) month = "0" + month;

            setTimeout(() => {
                this.reinstate.date =
                    this.renewal.date = `${date}/${month}/${year}`;
            }, 200);
        },
        fillReinstateForm(id: any) {
            this.focusedMemberId = id;
            this.reinstateTypes.map((item: any) => {
                if (item.code === "REIN") this.reinstate.type = item.id;
            });

            this.perimeters = [{ id: 1, label: "CDR" }];
            this.reinstate.perimeter = "CDR";
            this.disableDuePerimeter = true;
            this.fetchPriceCategories("reinstatement");

            const currentMonthNumber = new Date().getMonth() + 1;

            if (currentMonthNumber >= 1 && currentMonthNumber <= 8) {
                this.reinstate.relevant_year = new Date()
                    .getFullYear()
                    .toString();
            } else {
                this.reinstate.relevant_year = (
                    new Date().getFullYear() + 1
                ).toString();
            }
            this.setDate();
        },
        fetchPriceCategories(scope: any) {
            var payload = {
                p: this.currentPage,
                presult: 100,
                member: this.focusedMemberId,
                current_selection: {
                    type:
                        scope === "reinstatement"
                            ? this.reinstate.type
                            : this.renewal.type,
                    perimeter:
                        scope === "reinstatement"
                            ? this.reinstate.perimeter
                            : this.renewal.perimeter,
                },
            };
            if (typeof payload.current_selection.type === "string") {
                this.reinstateTypes.map((item: any) => {
                    if (item.label === payload.current_selection.type) {
                        payload.current_selection.type = item.id;
                    }
                });
            }

            if (
                !!payload.current_selection.type &&
                !!payload.current_selection.perimeter
            ) {
                membersApi.getPriceCategories(payload).then((response) => {
                    if (scope === "renewal") {
                        this.price_cat_options_next_step =
                            response.data.object_list;
                        this.price_cat_options_next_step.sort(
                            (a: any, b: any) => (a.amount > b.amount ? 1 : -1)
                        );
                    } else {
                        this.price_cat_options = response.data.object_list;
                        this.price_cat_options.sort((a: any, b: any) =>
                            a.amount > b.amount ? 1 : -1
                        );
                    }
                    if (this.price_cat_options.length === 1) {
                        if (scope === "reinstatement") {
                            this.reinstate.price_category =
                                this.price_cat_options[0].id;
                            this.setReinstateAmount(
                                this.reinstate.price_category,
                                false
                            );
                        } else if (scope === "renewal") {
                            this.renewal.price_category =
                                this.price_cat_options[0].id;
                            this.setReinstateAmount(
                                this.renewal.price_category,
                                true
                            );
                        }
                        if (this.perm_pos !== "0")
                            this.disableDuePriceCategory = true;
                    } else {
                        this.disableDuePriceCategory = false;
                    }
                });
            }
        },
        setReinstateAmount(e: any, linkedDue: boolean) {
            if (linkedDue === true) {
                this.priceCategories.map((item: any) => {
                    if (item.id === e) {
                        this.renewalAmount = item.amount;
                    }
                });
            } else {
                this.priceCategories.map((item: any) => {
                    if (item.id === e) {
                        this.reinstateAmount = item.amount;
                    }
                });
            }
        },
        handleSizeChange() {
            this.getArchives();
        },
        unarchiveMember() {
            var isEmpty = false;

            for (var key in this.reinstate) {
                if (
                    this.reinstate[key] === "" ||
                    this.reinstate[key] === null
                ) {
                    isEmpty = true;
                }
            }

            if (!isEmpty) this.omgdModal = true;
            else
                ElMessage({
                    message: "Please fill all the reinstate fields",
                    type: "error",
                });
        },
        addReinstate(addOmgd: boolean) {
            var payload = {
                reinstatement: {
                    member: this.focusedMemberId,
                    ...this.reinstate,
                },
                renewal: { member: this.focusedMemberId, ...this.renewal },
                omgd: addOmgd,
            };

            membersApi.addReinstatement(payload).then((res) => {
                if (res.success) {
                    ElMessage({
                        type: "success",
                        message: "Reinstatement added!",
                    });
                    this.omgdModal = false;
                    this.closeReinstateModal();
                    this.getArchives();
                } else {
                    ElMessage({
                        type: "error",
                        message: "Something went wrong!",
                    });
                }
            });
        },
        applyFilters() {
            var closeFilters = document.getElementById("filter") as any;
            closeFilters.click();
            this.getArchives();
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];
            this.getArchives();
        },
        queryChanged(query: string) {
            this.archiveSearch = query;
            this.getArchives();
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        redirectTo(memberId: any, newTab?: boolean) {
            if (newTab) {
                const routeData = this.router.resolve({
                    name: "member-details",
                    params: { member_id: memberId, page: this.currentPage },
                });
                window.open(routeData.href, "_blank");
            } else {
                this.router.push({
                    name: "member-details",
                    params: { member_id: memberId, page: this.currentPage },
                });
            }
        },
        deleteMembers(memberId?: number) {
            var membersIdToDelete = [] as any;
            var confirmMessage = "";

            if (memberId) {
                membersIdToDelete.push(memberId);
                confirmMessage = "Are you sure you want to delete this member?";
            } else {
                membersIdToDelete = this.selectedArchives;
                confirmMessage =
                    "You have requested the deletion of multiple members.<br>Would you like to confirm this choice?";
            }
            ElMessageBox.confirm(confirmMessage, "Confirmation", {
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
                customClass: "custom-modal",
                cancelButtonClass: "cancel-modal",
                dangerouslyUseHTMLString: true,
            })
                .then(() => {
                    membersApi.deleteMembers(membersIdToDelete).then(() => {
                        this.selectedArchives = [];
                        var topCheckbox = document.getElementById(
                            "topCheckbox"
                        ) as any;
                        topCheckbox.checked = false;
                        this.currentPage = 1;
                        ElMessage({
                            type: "info",
                            message: "Member deleted.",
                        });
                        this.getArchives();
                    });
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        getArchives() {
            this.loading = true;
            this.selectedArchives = [];
            this.archives = [];
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        filters: this.filtersPayload,
                        column: this.actualCol,
                        order: this.actualFilter,
                        status: "archived",
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        filters: this.filtersPayload,
                        status: "archived",
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                    status: "archived",
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "archived",
                };
            }

            if (this.archiveSearch) {
                payload = { ...payload, query: this.archiveSearch };
            }

            membersApi.getMembers(payload).then((res) => {
                this.archives = res.data.member_list;
                this.archivesCount = res.data.member_count;
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getArchives();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(
                document.getElementsByClassName(
                    "custom-checkbox-archives"
                ) as any
            );

            this.selectedArchives = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.archives.map((archive: any) => {
                    this.selectedArchives.push(archive.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedArchives = [];
            }
        },
        handleActionSelect(archive: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox" + archive.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedArchives.push(archive.id);
            } else {
                this.selectedArchives.map((archive: any) => {
                    if (archive.id === archive.id) {
                        this.selectedArchives.splice(
                            this.selectedArchives.indexOf(archive),
                            1
                        );
                    }
                });
            }
        },
        handlePagination(pageNumber: any) {
            this.selectedArchives = [];
            this.savedPage = undefined;
            this.currentPage = pageNumber;
            var topCheckbox = document.getElementById("topCheckbox") as any;
            topCheckbox.checked = false;
            this.getArchives();
        },
        getBadgeType(type: string) {
            switch (type) {
                case "Deceased":
                case "deceased":
                    return "badge-deceased";
                case "Terminated":
                case "terminated":
                    return "badge-deceased";
                case "Ajourned":
                case "ajourned":
                    return "badge-ajourned";
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
    },
});
